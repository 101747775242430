/**
 * @param {jQuery} error
 * @param {jQuery} el
 */
const errorPlacement = function (error, el) {
    const customError = $(bindhq.tpl.validation_error);

    // Add `form-error-message` class to the error element
    error.addClass('form-error-message');

    // Insert it inside the span that has `mb-0` class
    error.appendTo(customError.find('.bindhq-error-box'));

    const label = el.parent().find('label');

    // If the field is inline radio add the error to the group rahter than the field
    if (label.hasClass('radio-inline')) {
        el.closest('.form-group').append(customError);

        return;
    }

    // Prefer to put it into the label
    if (0 !== label.length) {
        el.parent().append(customError);

        return;
    }

    // Try to put at end of controls section
    const controls = el.closest('.controls');
    if (0 !== controls.length) {
        controls.append(customError);

        return;
    }

    // cannot find a label as a parent, so append after the error
    customError.insertAfter(el);
};

/**
 * @param {jQuery} element
 * @param {String} errorClass
 * @param {String} validClass
 */
const highlight = function (element, errorClass, validClass) {
    if (element.type === 'radio') {
        this.findByName(element.name)
            .addClass(errorClass)
            .removeClass(validClass);
    } else {
        $(element).addClass(errorClass).removeClass(validClass);
    }
    $(element).closest('.form-group').find('span.badge-danger').show();
};

/**
 * @param {jQuery} element
 * @param {String} errorClass
 * @param {String} validClass
 */
const unhighlight = function (element, errorClass, validClass) {
    if (element.type === 'radio') {
        this.findByName(element.name)
            .removeClass(errorClass)
            .addClass(validClass);
    } else {
        $(element).removeClass(errorClass).addClass(validClass);
    }

    const errorMessages = $(element).closest('.form-group, .controls');

    if (
        errorMessages.find('span.form-error-message, .invalid-feedback')
            .length === 0
    ) {
        return;
    }

    // remove Symfony error messages, and previous validation messages
    errorMessages.find('span.form-error-message, .invalid-feedback').remove();

    $(element)
        .removeClass('is-invalid')
        .closest('.form-group')
        .find('span.badge-danger')
        .hide();
};

/**
 * @param {jQuery} form
 * @param {jQuery} validator
 */
const invalidHandler = function (form, validator) {
    const errors = validator.numberOfInvalids();
    if (errors) {
        const errorElement = validator.errorList[0].element;

        const select2 =
            errorElement.classList.contains('select2') ||
            errorElement.classList.contains('select2-offscreen') ||
            errorElement.classList.contains('ajax-select');

        if (select2) {
            const input = errorElement.parentElement.querySelector(
                '.select2-container:not(.no-focus-on-error) .select2-focusser',
            );

            // This is a workaround to prevent an error when the `select2-focusser` class is not found for any select2 control.
            if (null === input) {
                return;
            }

            const isVisible = input.offsetWidth > 0 || input.offsetHeight > 0;

            if (isVisible) {
                errorElement.parentElement
                    .querySelector('.select2-container')
                    .scrollIntoView({ behavior: 'smooth', block: 'start' });
                input.focus({ preventScroll: true });
            }
        } else {
            errorElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            errorElement.focus({ preventScroll: true });
        }
    }
};

module.exports = { errorPlacement, highlight, unhighlight, invalidHandler };

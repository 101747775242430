(function () {
    'use strict';

    bindhq.nsIn('policyfilter.filter', {
        /**
         * @param {jQuery} container
         */
        selectAllClick: function (container) {
            const selectAll = $('.select-all', container);

            $('.select-policy', container).attr(
                'checked',
                selectAll.is(':checked'),
            );
        },

        /**
         * @param {jQuery} container
         */
        selectPolicyClick: function (container) {
            $('.select-all', container).attr('checked', false);
        },

        /**
         * @param {jQuery} container
         */
        initSelectAll: function (container) {
            $('.select-all', container).click(
                _.partial(this.selectAllClick, container),
            );

            $('.select-policy', container).click(
                _.partial(this.selectPolicyClick, container),
            );
        },

        /**
         * @param {jQuery} container
         * @param {String} baseUrl
         * @param {String} formName
         *
         * @return {String}
         */
        selectAllUrl: function (container, baseUrl, formName) {
            const params = {};

            params[formName + '[type]'] = $('.select-all', container).val();

            const pageParams = $.param(params);

            const formParams = $('form', container)
                .serialize()
                .replace(/policyfilter_filter/g, formName);

            return baseUrl + '?' + pageParams + '&' + formParams;
        },

        /**
         * @param {jQuery} container
         * @param {String} baseUrl
         *
         * @return {String}
         */
        selectedPoliciesUrl: function (container, baseUrl, formName) {
            const policies = $('.select-policy:checked');
            const ids = _.pluck(policies, 'value');
            const params = {};

            params[formName + '[type]'] = 'selectPolicies';
            params[formName + '[ids]'] = ids.join(',');

            const pageParams = $.param(params);

            return baseUrl + '?' + pageParams;
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        actionClicked: function (container, evt) {
            const link = $(evt.currentTarget);
            const baseUrl = link.data('url');
            const formName = link.data('form-name');
            const url = this.selectedPoliciesUrl(container, baseUrl, formName);

            bindhq.modalAjax.showUrl(url);
        },

        /**
         * @param {jQuery} container
         */
        initActions: function (container) {
            $('.policyfilter-action', container).click(
                _.partial(this.actionClicked, container),
            );
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            this.initSelectAll(container);
            this.initActions(container);
        },
    });
})();

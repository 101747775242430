import { Controller } from '@hotwired/stimulus';
const CodeMirror = require('codemirror');
export default class extends Controller {
    connect() {
        const theme = this.#isDarkMode() ? 'nord' : 'idea';
        CodeMirror.fromTextArea(this.element, {
            mode: {
                name: 'javascript',
                json: true,
            },
            theme: theme,
            lineNumbers: true,
        });
    }

    #isDarkMode() {
        return (
            window.matchMedia &&
            window.matchMedia('(prefers-color-scheme: dark)').matches
        );
    }
}

import { Controller } from '@hotwired/stimulus';

const FormValidator = require('../js/BindHQ/Validation/FormValidator');

export default class extends Controller {
    connect() {
        $(this.element).validator = $(this.element).validate({
            normalizer: function (value) {
                return typeof value === 'string' ? value.trim() : value;
            },
            ignoreTitle: true,
            focusInvalid: false,
            ignore: ':hidden:not(.wysiwyg)',
            errorElement: 'span',
            errorPlacement: FormValidator.errorPlacement,
            highlight: FormValidator.highlight,
            unhighlight: FormValidator.unhighlight,
            invalidHandler: FormValidator.invalidHandler,
        });
    }
}

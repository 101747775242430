(function () {
    'use strict';

    bindhq.nsIn('forms.policy', {
        /**
         * @param {String} source
         * @param {jQuery.Event} evt
         * @param {Object} message
         */
        onInvoiceUpserted: function (source, evt, message) {
            $('a[data-invoice-id="' + message.invoiceId + '"]').removeClass(
                'invoice-unavailable',
            );
        },

        /**
         *
         */
        initContainer: function (container) {
            $('body').on(
                'policies.invoiceUpserted',
                _.partial(this.onInvoiceUpserted, container),
            );
        },
    });
})();

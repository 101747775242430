import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['override', 'period'];

    connect() {
        this.toggle();
    }

    toggle() {
        this.periodTarget.value = this.overrideTarget.checked
            ? this.periodTarget.value
            : '';
        this.periodTarget.disabled = !this.overrideTarget.checked;
    }
}

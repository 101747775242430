import { Controller } from '@hotwired/stimulus';

const { v4: uuidv4 } = require('uuid');

export default class extends Controller {
    connect() {
        if (!this.element.value) {
            this.element.value = uuidv4();
        }
    }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'fileInput',
        'useDefaultImageField',
        'defaultImageWrapper',
        'imageFieldWrapper',
        'useDefaultImageLink',
    ];

    static values = {
        values: Object,
        default: String,
    };

    isRequiredField = false;

    connect() {
        this.isRequiredField = this.fileInputTarget.hasAttribute('required');

        if (this.useDefaultImageFieldTarget.value === '1') {
            this.useDefaultImage();
        } else {
            this.removeDefaultImage(null);
        }
    }

    removeDefaultImage(e) {
        if (e) {
            e.preventDefault();
        }

        this.defaultImageWrapperTarget.classList.add('d-none');
        this.imageFieldWrapperTarget.classList.remove('d-none');
        this.useDefaultImageLinkTarget.classList.remove('d-none');
        this.useDefaultImageFieldTarget.value = '0';

        if (this.isRequiredField) {
            this.fileInputTarget.setAttribute('required', 'required');
        }
    }

    useDefaultImage(e) {
        if (e) {
            e.preventDefault();
        }

        this.imageFieldWrapperTarget.classList.add('d-none');
        this.defaultImageWrapperTarget.classList.remove('d-none');
        this.useDefaultImageLinkTarget.classList.add('d-none');
        this.useDefaultImageFieldTarget.value = '1';

        if (this.isRequiredField) {
            this.fileInputTarget.removeAttribute('required');
        }
    }
}

const { v4: uuidv4 } = require('uuid');
const { DateTime } = require('luxon');
const numeral = require('numeral');

(function () {
    'use strict';

    /**
     * @param {Integer}
     */
    const PER_PAGE = 50;

    /**
     * @param {String}
     */
    const DEFAULT_OPTIONS = {
        perPage: PER_PAGE,
        applyImmediately: true,
    };

    /**
     * @param {Integer} value
     *
     * @return {Integer}
     */
    function inc(value) {
        return value + 1;
    }

    /**
     * @param {Integer} value
     *
     * @return {Integer}
     */
    function dec(value) {
        return value - 1;
    }

    /**
     * @param {String} date
     *
     * @return {DateTime|null} Unix timestamp of date, adjusted for user's timezone, or '~' if it's `~`
     */
    function toUnix(date) {
        if (null !== date && '' !== date) {
            if ('~' === date) {
                return date;
            }

            return DateTime.fromFormat(date, 'MM/dd/y').toSeconds();
        }

        return null;
    }

    /**
     * @param {String} type
     *
     * @return {Function}
     */
    function makeTextFilter(type) {
        return function (item, criteria) {
            return (
                !criteria[type] ||
                (item[type] || '').indexOf(criteria[type]) !== -1
            );
        };
    }

    /**
     * @param {String} type
     *
     * @return {Function}
     */
    function makeBooleanFilter(type) {
        return function (item, criteria) {
            return (
                undefined === criteria[type] || criteria[type] === item[type]
            );
        };
    }

    /**
     * @param {String} type
     *
     * @return {Function}
     */
    function makeIntegerFilter(type) {
        return function (item, criteria) {
            return !criteria[type] || item[type] === criteria[type];
        };
    }

    /**
     * @return {Function}
     */
    function makeDateFilter() {
        return function (item, criteria) {
            const value = item[criteria.date.type];

            if (criteria.date.from && (!value || value < criteria.date.from)) {
                return false;
            }

            if (criteria.date.to && (!value || value > criteria.date.to)) {
                return false;
            }

            return true;
        };
    }

    /**
     * @param {jQuery} container
     */
    function TransactionTable(container, options) {
        this.container = container;
        this.tbody = $('tbody', container);
        this.options = _.extend(
            {},
            DEFAULT_OPTIONS,
            this.tableOptions(container),
            options,
        );

        this.items = {};
        this.currentItems = [];
        this.currentPageIndex = 1;
        this.newItemCount = 0;
        this.criteria = {
            sort: {
                field: 'postedAt',
                dir: 'desc',
            },
            marked: undefined,
            notVoid: undefined,
            date: this.getInputDate(),
            insured: $('.transaction-filter[name*="insured"]', container).val(),
            policy: $('.transaction-filter[name*="policy"]', container).val(),
            transactionState: $(
                '.transaction-filter[name=transactionState]',
                container,
            ).val(),
            payableType: $(
                '.transaction-filter[name=payableType]:checked',
                container,
            ).val(),
            billingType: $(
                '.transaction-filter[name=billingType]:checked',
                container,
            ).val(),
        };

        this.onTransactionLoaded = this.defaultTransactionLoader;

        if (this.options.onTransactionLoaded) {
            this.onTransactionLoaded =
                this.options.onTransactionLoaded.bind(this);
        }

        if (this.options.sortField) {
            this.criteria.sort.field = this.options.sortField;
        }

        if (this.options.sortOrder) {
            this.criteria.sort.dir = this.options.sortOrder;
        }

        if (this.options.postedAtIndex) {
            this.readyToSort(this.options.postedAtIndex, 'postedAt', container);
        }

        if (this.options.effectiveAtIndex) {
            this.readyToSort(
                this.options.effectiveAtIndex,
                'effectiveAt',
                container,
            );
        }

        container.on(
            'click',
            '.transaction-table-new-items a',
            this.onFiltersChanged.bind(this),
        );
        container.on(
            'click',
            '.transaction-checkbox-filter',
            this.onCheckboxFilterClick.bind(this),
        );
        container.on(
            'change',
            '.filter-date',
            this.onDateFilterChange.bind(this),
        );
        container.on(
            'keyup',
            'input[type=text].transaction-filter',
            this.onTextFilterChange.bind(this),
        );
        container.on(
            'change',
            'input[type=text].ajax-select.transaction-filter',
            this.onSelect2FilterChange.bind(this),
        );
        container.on(
            'click',
            'input[type=radio].transaction-filter',
            this.onRadioFilterChange.bind(this),
        );
        container.on(
            'change',
            'select.tomselected',
            this.onTomselectFilterChange.bind(this),
        );
        container.on(
            'transaction_table.filters_changed',
            _.debounce(this.onFiltersChanged.bind(this), 500),
        );
        container.on(
            'payment_pagination_changed',
            _.debounce(this.onPaginationChanged.bind(this), 100),
        );
        container.on(
            'transaction_table.item_added',
            _.debounce(this.refreshPagination.bind(this), 500),
        );
        container.on(
            'click',
            '.btn-pagination-previous',
            _.partial(this.onPaginationClick.bind(this), dec),
        );
        container.on(
            'click',
            '.btn-pagination-next',
            _.partial(this.onPaginationClick.bind(this), inc),
        );
        container.on(
            'change',
            'select.financed-filter',
            this.onFinancedFilterChange.bind(this),
        );
        $(document).on(
            'table-listing.remove-filter',
            _.partial(this.onFiltersRemoved.bind(this)),
        );

        if (this.options.totalIndex) {
            this.readyToSort(this.options.totalIndex, 'total', container);
        }

        if (this.options.paidIndex) {
            this.readyToSort(this.options.paidIndex, 'paid', container);
        }

        if (this.options.dueIndex) {
            this.readyToSort(this.options.dueIndex, 'due', container);
        }

        if (this.options.referenceIndex) {
            this.readyToSort(
                this.options.referenceIndex,
                'reference',
                container,
            );
        }

        if (this.options.descriptionIndex) {
            this.readyToSort(
                this.options.descriptionIndex,
                'description',
                container,
            );
        }

        if (this.options.policyIndex) {
            this.readyToSort(this.options.policyIndex, 'policy', container);
        }

        if (this.options.insuredIndex) {
            this.readyToSort(this.options.insuredIndex, 'insured', container);
        }

        if (this.options.producerIndex) {
            this.readyToSort(this.options.producerIndex, 'producer', container);
        }

        if (this.options.financeCompanyIndex) {
            this.readyToSort(
                this.options.financeCompanyIndex,
                'financeCompany',
                container,
            );
        }
    }

    TransactionTable.prototype.toOption = function (acc, element) {
        const item = $(element);

        for (const k in item.data()) {
            if (k === 'transactionTableColumn') {
                const option = item.data(k) + 'Index';

                acc[option] = item.index() + 1;
            }
        }

        return acc;
    };

    TransactionTable.prototype.tableOptions = function (container) {
        const headers = $('thead th', container).toArray();

        return _.reduce(headers, this.toOption, {});
    };

    /**
     * @param {Integer} index
     * @param {String} criteria
     * @param {jQuery} container
     */
    TransactionTable.prototype.readyToSort = function (
        index,
        criteria,
        container,
    ) {
        const columnSelector = 'th:nth-child(' + index + ')';
        container.on(
            'click',
            columnSelector,
            _.partial(this.onSortClick.bind(this), criteria),
        );
        $(columnSelector).addClass('sort-column');
    };

    /**
     * @param {Object} item
     * @param {jQuery} tbody
     */
    TransactionTable.prototype.defaultTransactionLoader = function (
        item,
        tbody,
    ) {
        this.indexItem(item, tbody);
    };

    /**
     * @param {jQuery} item
     *
     * @return {Boolean}
     */
    TransactionTable.prototype.hasItem = function (item) {
        const id = this.idForItem(item);

        return undefined !== this.items[id];
    };

    /**
     * @return {Array}
     */
    TransactionTable.prototype.getItems = function () {
        return _.toArray(this.items);
    };

    /**
     * @return {Array}
     */
    TransactionTable.prototype.getCurrentItems = function () {
        return this.currentItems;
    };

    /**
     * @return {Array}
     */
    TransactionTable.prototype.getCurrentPageItems = function () {
        if (
            bindhq.abacus.transactionTable.NO_PAGINATION ===
            this.options.perPage
        ) {
            return this.currentItems;
        }

        const startIndex = (this.currentPageIndex - 1) * this.options.perPage;
        const endIndex = startIndex + this.options.perPage;

        return _.chain(this.currentItems).slice(startIndex, endIndex).value();
    };

    /**
     * @param {jQuery.Event} evt
     */
    TransactionTable.prototype.onRadioFilterChange = function (evt) {
        const criteria = {};
        const source = $(evt.currentTarget);
        const name = source.attr('name');
        let value = source.val();

        if (source.hasClass('transaction-filter-boolean')) {
            if (value === 'true') {
                value = true;
            } else if (value === 'false') {
                value = false;
            } else {
                value = undefined;
            }
        }

        criteria[name] = value;

        this.container.trigger(
            $.Event('transaction_table.filters_changed', {
                criteria: criteria,
            }),
        );
    };

    /**
     * @param {jQuery.Event} evt
     */
    TransactionTable.prototype.onTomselectFilterChange = function (evt) {
        const criteria = {};
        const source = evt.currentTarget;
        const name = source.getAttribute('name');
        const value = source.tomselect.getValue();

        criteria[name] = value;

        this.container.trigger(
            $.Event('transaction_table.filters_changed', {
                criteria: criteria,
            }),
        );
    };

    /**
     * @param {jQuery.Event} evt
     */
    TransactionTable.prototype.onTextFilterChange = function (evt) {
        const criteria = {};
        const source = $(evt.currentTarget);
        const type = source.data('name');

        criteria[type] = source.val().toLowerCase();

        this.container.trigger(
            $.Event('transaction_table.filters_changed', {
                criteria: criteria,
            }),
        );
    };

    TransactionTable.prototype.onSelect2FilterChange = function (evt) {
        const criteria = {};
        const source = $(evt.currentTarget);
        const type = source.data('name');
        const data = source.select2('data');
        const property = source.data('property');

        criteria[type] = data ? data[property].toLowerCase() : null;

        this.container.trigger(
            $.Event('transaction_table.filters_changed', {
                criteria: criteria,
            }),
        );
    };

    TransactionTable.prototype.onFinancedFilterChange = function (evt) {
        const value = {
            true: true,
            false: false,
        }[evt.currentTarget.value];

        const criteria = {
            financed: value,
        };

        if (value === false) {
            $('.finance-company-filter')
                .val(null)
                .trigger('change')
                .prop('disabled', true);
            criteria.financeCompany = '';
        } else {
            $('.finance-company-filter').prop('disabled', false);
        }

        this.container.trigger(
            $.Event('transaction_table.filters_changed', {
                criteria,
            }),
        );
    };

    /**
     * @param {String} type
     * @param {jQuery.Event} evt
     */
    TransactionTable.prototype.onSortClick = function (type, evt) {
        const th = $(evt.currentTarget);
        th.siblings().removeClass('sort-asc sort-desc');

        if (th.hasClass('sort-asc')) {
            th.removeClass('sort-asc').addClass('sort-desc');

            this.container.trigger(
                $.Event('transaction_table.filters_changed', {
                    applyImmediately: true,
                    criteria: {
                        sort: {
                            field: type,
                            dir: 'desc',
                        },
                    },
                }),
            );
        } else {
            th.removeClass('sort-desc').addClass('sort-asc');

            this.container.trigger(
                $.Event('transaction_table.filters_changed', {
                    applyImmediately: true,
                    criteria: {
                        sort: {
                            field: type,
                            dir: 'asc',
                        },
                    },
                }),
            );
        }
    };

    /**
     * @param {jQuery.Event} evt
     */
    TransactionTable.prototype.onCheckboxFilterClick = function (evt) {
        const source = $(evt.currentTarget);
        const type = source.attr('name');
        const criteria = {};

        criteria[type] = source.prop('checked') ? true : undefined;

        this.container.trigger(
            $.Event('transaction_table.filters_changed', {
                criteria: criteria,
            }),
        );
    };

    /**
     * @param {jQuery.Event} evt
     */
    TransactionTable.prototype.onDateFilterChange = function (evt) {
        const criteria = this.getInputDate();

        this.container.trigger(
            $.Event('transaction_table.filters_changed', {
                criteria: { date: criteria },
            }),
        );
    };

    TransactionTable.prototype.getInputDate = function () {
        const fromDateValue = $('.filter-date-start', this.container).val();
        const toDateValue = $('.filter-date-end', this.container).val();

        const fromDate = fromDateValue
            ? DateTime.fromISO(fromDateValue.substring(0, 10))
            : null;

        const toDate = toDateValue
            ? DateTime.fromISO(toDateValue.substring(0, 10))
            : null;

        return {
            from: fromDate ? fromDate.toSeconds() : null,
            to: toDate ? toDate.toSeconds() : null,
            type: $('.date-type-filter:checked', this.container).val(),
        };
    };

    /**
     * @param {Object} item
     *
     * @return {Boolean}
     */
    TransactionTable.prototype.matchesFilters = function (item) {
        const filters = [
            makeBooleanFilter('marked'),
            makeBooleanFilter('notVoid'),
            makeBooleanFilter('okToPay'),
            makeTextFilter('policy'),
            makeTextFilter('insured'),
            makeDateFilter(),
            makeTextFilter('transactionState'),
            makeTextFilter('payableType'),
            makeIntegerFilter('billingType'),
            makeTextFilter('financeCompany'),
            makeBooleanFilter('financed'),
        ];

        return _.every(
            filters,
            function (f) {
                return f(item, this.criteria);
            }.bind(this),
        );
    };

    /**
     * @return {Function}
     */
    TransactionTable.prototype.makeFilterer = function () {
        return function (item) {
            return this.matchesFilters(item);
        }.bind(this);
    };

    /**
     * @return {Function}
     */
    TransactionTable.prototype.makeSorter = function () {
        return function (a, b) {
            if (this.criteria.sort.field) {
                const x = a[this.criteria.sort.field];
                const y = b[this.criteria.sort.field];

                // always sort nulls last
                if (x === null) {
                    return 1;
                }
                if (y === null) {
                    return -1;
                }

                if (x === y) {
                    return 0;
                }

                const dir = this.criteria.sort.dir === 'desc' ? 1 : -1;

                return x < y ? 1 * dir : -1 * dir;
            } else {
                // sort by marked first
                if (a.marked) {
                    return -1;
                }
                if (b.marked) {
                    return 1;
                }
            }

            return 0;
        }.bind(this);
    };

    /**
     * @param {Object} criteria
     */
    TransactionTable.prototype.addCriteria = function (criteria) {
        this.criteria = _.extend(this.criteria, criteria);
    };

    /**
     * @param {jQuery.Event} evt
     */
    TransactionTable.prototype.onFiltersChanged = function (evt) {
        this.addCriteria(evt.criteria);

        if (this.options.applyImmediately || evt.applyImmediately) {
            this.applyFilters();
        }
    };

    /**
     * @param {jQuery.Event} evt
     */
    TransactionTable.prototype.onFiltersRemoved = function (evt) {
        const input = $('#' + evt.criteria);
        const criteriaName = (() => {
            const name = input.attr('name');
            if (name.startsWith('accounting_transactions_filter')) {
                const match = name.match(/\[(.*?)\]/);
                return match ? match[1] : name;
            }
            return name;
        })();

        if (input.attr('type') === 'date') {
            if (input.hasClass('filter-date-start')) {
                this.criteria.date.from = null;
            } else if (input.hasClass('filter-date-end')) {
                this.criteria.date.to = null;
            }
        } else if (criteriaName in this.criteria) {
            this.criteria = _.omit(this.criteria, criteriaName);
        }

        this.applyFilters();
    };

    /**
     *
     */
    TransactionTable.prototype.applyFilters = function () {
        this.currentPageIndex = 1;
        this.currentItems = _.chain(this.items)
            .filter(this.makeFilterer())
            .sort(this.makeSorter())
            .value();

        this.refreshUI();
    };

    /**
     *
     */
    TransactionTable.prototype.refreshUI = function () {
        this.refreshTable();
        this.refreshPagination();
        this.refreshPayables();

        this.container.trigger($.Event('transaction_table.filtered', {}));

        if (!this.isLoaded) {
            this.container
                .find('tbody')
                .removeClass('transaction-table-loading')
                .find('.transaction-table-loader')
                .remove();

            this.isLoaded = true;
        }

        this.newItemCount = 0;
    };

    /**
     * @param {Integer} currentPage
     * @param {Object} params
     */
    TransactionTable.prototype.loadTransactions = function (
        currentPage,
        params,
    ) {
        const tbody = $('.transaction-table-content', this.container);
        const defaultParams = {
            page: currentPage,
            template: '_transactions',
        };
        const config = {
            url: tbody.data('transaction-url'),
            data: _.extend(defaultParams, params),
            success: function (source) {
                const response = $(source);
                const content = response.hasClass('transaction-table-content')
                    ? response
                    : $('.transaction-table-content', response);
                const items = $('.transaction-item, .payment-entry', content);

                if (items.length === 0) {
                    this.onLoaded();
                } else {
                    _.each(
                        items,
                        function (item) {
                            this.onTransactionLoaded($(item));
                        }.bind(this),
                    );

                    const itemCount = Object.keys(this.items).length;
                    const itemTotal =
                        content.data('transaction-total') ||
                        tbody.data('transaction-total');
                    const percentage = Math.min(
                        Math.round((itemCount / itemTotal) * 100),
                        100,
                    );

                    $('.transaction-table-loader td', this.container).html(
                        'Loading transactions... ' + percentage + '%',
                    );

                    this.refreshNewItems(items, percentage);
                    this.loadTransactions(currentPage + 1, params);
                }
            }.bind(this),
        };

        $.ajax(config);
    };

    TransactionTable.prototype.showLoading = function () {
        const content = this.container
            .find('.transaction-table-content')
            .addClass('transaction-table-loading');

        $('<tr></tr>')
            .addClass('transaction-table-loader')
            .append('<td colspan="999">Loading transactions...')
            .appendTo(content);
    };

    /**
     *
     */
    TransactionTable.prototype.loadAllTransactions = function () {
        this.showLoading();

        this.isLoaded = false;

        this.loadTransactions(1, { 'closed-transactions': true });
    };

    /**
     * @param {Array} items
     */
    TransactionTable.prototype.refreshNewItems = function (items, percentage) {
        this.newItemCount += items.length;

        if (this.isLoaded) {
            const content = $('.transaction-table-content', this.container);
            const tr = $('.transaction-table-new-items', content);
            const message =
                percentage === 100
                    ? this.newItemCount +
                      ' new transactions available, <a>show now</a>'
                    : 'Still loading transactions... ' +
                      percentage +
                      '% complete. ' +
                      this.newItemCount +
                      ' new items available, <a>show now</a>';

            if (tr.length !== 0) {
                $('td', tr).html(message);
            } else {
                const td = $('<td></td>').attr('colspan', 999).html(message);

                $('<tr></tr>')
                    .addClass('transaction-table-new-items')
                    .append(td)
                    .prependTo(content);
            }
        }
    };

    /**
     *
     */
    TransactionTable.prototype.onLoaded = function () {
        if (!this.isLoaded) {
            this.container.trigger(
                $.Event('transaction_table.filters_changed', {}),
            );
        }
        this.container
            .find('tbody')
            .closest('table')
            .trigger(
                $.Event('transaction_table.items_loaded', {
                    items: this.items,
                }),
            );
        this.container.find('tbody').closest('table').addClass('items-loaded');
    };

    /**
     *
     */
    TransactionTable.prototype.refreshTable = function () {
        const newItems = this.getCurrentPageItems();
        const content = $('.transaction-table-content', this.container);

        $('tr.no-results, tr.transaction-table-new-items', content).remove();
        $('tr:not(.transaction-table-loader)', content).detach();

        _.forEach(newItems, function (item) {
            item.tbody.append(item.element);
        });

        if (newItems.length === 0) {
            const td = $('<td></td>')
                .attr('colspan', 999)
                .html('No transactions matching current filters...');

            $('<tr></tr>').addClass('no-results').append(td).appendTo(content);
        }
    };

    /**
     *
     */
    TransactionTable.prototype.refreshPagination = function () {
        const html = bindhq.util.template(
            bindhq.tpl.transaction_table_pagination,
            {
                currentItems: this.currentItems,
                currentPageIndex: this.currentPageIndex,
                perPage: this.options.perPage,
            },
        );

        $('.transaction-table-pagination', this.container).html(html);
    };

    /**
     *
     */
    TransactionTable.prototype.refreshPayables = function () {
        const items = $(
            '[data-related-transaction-external-id]',
            this.container,
        );
        const loadingClass = 'is-payable-loading';
        const loadedClass = 'is-payable-loaded';

        if (items.length === 0) {
            return;
        }

        const itemMap = _.reduce(
            items,
            function (acc, item) {
                const type = item.dataset.relatedTransactionType;
                const externalId = item.dataset.relatedTransactionExternalId;

                acc[externalId] = {
                    type: type,
                    externalId: externalId,
                    node: item,
                };

                return acc;
            },
            {},
        );

        const payablesUrl = this.container.data('payables-url');
        const payablesFormName =
            this.container.data('payables-form-name') || 'abacus_payables';
        const data = {};

        data[payablesFormName + '[items]'] = _.map(
            itemMap,
            function (data) {
                return data.type + ':' + data.externalId;
            },
            itemMap,
        );

        const config = {
            url: payablesUrl,
            data: data,
            success: function (data) {
                _.each(
                    data,
                    function (data) {
                        if (!itemMap[data.external_id]) {
                            return;
                        }

                        const item = $(itemMap[data.external_id].node);

                        if (data.ok_to_pay) {
                            item.addClass(loadedClass)
                                .addClass('tick-value')
                                .attr('data-value', 'true');

                            this.indexItem(item.closest('tr'));
                        } else if (data.mostly_ok_to_pay) {
                            const amount = numeral(data.amount_to_pay);
                            const title =
                                'Amount to pay: ' + amount.format('(0,0.00)');

                            item.addClass(loadedClass)
                                .addClass('tick-value')
                                .addClass('amber')
                                .attr('data-value', 'true')
                                .attr('title', title);

                            this.indexItem(item.closest('tr'));
                        } else {
                            item.addClass('not-payable');
                        }
                    }.bind(this),
                );

                _.each(itemMap, function (item) {
                    $(item.node).removeClass(loadingClass);
                });
            }.bind(this),
            error: function () {
                items.removeClass(loadingClass);
            },
        };

        items
            .addClass(loadingClass)
            .removeAttr('data-related-transaction-external-id');

        $.ajax(config);
    };

    /**
     * @param {jQuery.Event} evt
     */
    TransactionTable.prototype.onPaginationChanged = function (evt) {
        const newIndex = evt.newPageIndex;
        const maxPages = Math.ceil(
            this.currentItems.length / this.options.perPage,
        );
        const wrapper = $(evt.source).closest('.transaction-table-pagination');
        const scrollTo = wrapper.hasClass('transaction-table-pagination-bottom')
            ? $('.transaction-table-content', this.container).position()
            : -1;

        if (newIndex > 0 && newIndex <= maxPages) {
            this.currentPageIndex = newIndex;
            this.refreshUI();
        }

        if (scrollTo !== -1) {
            $('html, body').animate({ scrollTop: scrollTo.top - 220 }, 500);
        }
    };

    /**
     * @param {Function} modifier
     * @param {jQuery.Event} evt
     */
    TransactionTable.prototype.onPaginationClick = function (modifier, evt) {
        evt.preventDefault();

        this.container.trigger(
            $.Event('payment_pagination_changed', {
                newPageIndex: modifier(this.currentPageIndex),
                source: evt.currentTarget,
            }),
        );
    };

    /**
     * @param {jQuery} entry
     *
     * @return {Object}
     */
    TransactionTable.prototype.itemForEntry = function (entry) {
        const id = entry.data('id');

        return this.items[id];
    };

    /**
     * @param {jQuery} item
     *
     * @return {String}
     */
    TransactionTable.prototype.idForItem = function (item) {
        return item.data('id') || uuidv4();
    };

    /**
     * @param {jQuery} item
     */
    TransactionTable.prototype.initItem = function (item) {
        const thisId = Object.keys(this.items).length;

        $('[name]', item).each(function () {
            const current = $(this).attr('name');
            const updated = current.replace(
                /\[transactions\]\[\d+\]/g,
                '[transactions][' + thisId + ']',
            );

            $(this).attr('name', updated);
        });

        $('[id]', item).each(function () {
            const current = $(this).attr('id');
            const updated = current.replace(
                /transactions_\d+_/g,
                'transactions_' + thisId + '_',
            );

            $(this).attr('id', updated);
        });
    };

    /**
     * @param {jQuery} item
     * @param {jQuery} tbody
     *
     * @return {Object}
     */
    TransactionTable.prototype.indexItem = function (item, tbody) {
        if (!this.hasItem(item)) {
            this.initItem(item);
        }

        const id = this.idForItem(item);
        const amountValue = $('input[type=text]', item).val();
        const amountDueValue = $('.entry-amount-due', item).data('value');
        const amountAppliedValue = $('.entry-amount-applied', item).data(
            'value',
        );

        const data = {
            id: id,
            isCredit: item.hasClass('entry-credit'),
            notVoid: undefined,
            okToPay: false,
            originalPolicy: '',
            policy: '',
            insured: '',
            originalInsured: '',
            marked: !!$('input[type=checkbox]', item).prop('checked'),
            originalPostedAt: null,
            postedAt: null,
            originalEffectiveAt: null,
            effectiveAt: null,
            element: item.data('id', id),
            tbody: tbody || this.tbody,
            transactionState: null,
            payableType: null,
            billingType: null,
            total: '',
            paid: '',
            due: '',
            amount: numeral(amountValue),
            amountDue: numeral(amountDueValue),
            amountApplied: numeral(amountAppliedValue),
            originalDescription: null,
            description: null,
            originalFinanceCompany: '',
            financeCompany: '',
            financed: undefined,
        };

        if (this.options.notVoidIndex) {
            data.notVoid =
                $(
                    'td:nth-child(' + this.options.notVoidIndex + ')',
                    item,
                ).text() === '1';
        }

        if (this.options.okToPayIndex) {
            data.okToPay = !!$(
                'td:nth-child(' + this.options.okToPayIndex + ')',
                item,
            ).data('value');
        }

        if (this.options.policyIndex) {
            data.originalPolicy = $(
                'td:nth-child(' +
                    this.options.policyIndex +
                    ') :not(span.warning-message)',
                item,
            ).text();
            data.policy = data.originalPolicy.toLowerCase().trim();
        }

        if (this.options.insuredIndex) {
            data.originalInsured = $(
                'td:nth-child(' + this.options.insuredIndex + ')',
                item,
            ).text();
            data.insured = data.originalInsured.toLowerCase();
        }

        if (this.options.producerIndex) {
            data.originalProducer = $(
                'td:nth-child(' + this.options.producerIndex + ')',
                item,
            ).text();
            data.producer = data.originalProducer.toLowerCase();
        }

        if (this.options.postedAtIndex) {
            const postedAt = $(
                'td:nth-child(' + this.options.postedAtIndex + ')',
                item,
            )
                .text()
                .trim();
            data.originalPostedAt = postedAt;
            data.postedAt = toUnix(postedAt);
        }

        if (this.options.effectiveAtIndex) {
            const effectiveAt = $(
                'td:nth-child(' + this.options.effectiveAtIndex + ')',
                item,
            )
                .text()
                .trim();
            data.originalEffectiveAt = effectiveAt;
            data.effectiveAt = toUnix(effectiveAt);
        }

        if (this.options.payableTypeIndex) {
            const td = $(
                'td:nth-child(' + this.options.payableTypeIndex + ')',
                item,
            );
            data.payableType = td.data('sort-value') || td.text();
        }

        if (this.options.transactionStateIndex) {
            data.transactionState = $(
                'td:nth-child(' + this.options.transactionStateIndex + ')',
                item,
            ).data('sort-value');
        }

        if (this.options.billingTypeIndex) {
            data.billingType =
                $(
                    'td:nth-child(' + this.options.billingTypeIndex + ')',
                    item,
                ).data('sort-value') + '';
        }

        if (this.options.amountIndex) {
            data.amount = numeral(
                $('td:nth-child(' + this.options.amountIndex + ')', item).data(
                    'sort-value',
                ) + '',
            );
        }

        if (this.options.totalIndex) {
            data.total = numeral(
                $('td:nth-child(' + this.options.totalIndex + ')', item).data(
                    'sort-value',
                ) + '',
            );
        }

        if (this.options.paidIndex) {
            data.paid = numeral(
                $('td:nth-child(' + this.options.paidIndex + ')', item).data(
                    'sort-value',
                ) + '',
            );
        }

        if (this.options.dueIndex) {
            data.due = numeral(
                $('td:nth-child(' + this.options.dueIndex + ')', item).data(
                    'sort-value',
                ) + '',
            );
        }

        if (this.options.referenceIndex) {
            data.reference = $(
                'td:nth-child(' + this.options.referenceIndex + ')',
                item,
            )
                .text()
                .toLowerCase();
        }

        if (this.options.descriptionIndex) {
            data.originalDescription = $(
                'td:nth-child(' + this.options.descriptionIndex + ')',
                item,
            ).text();
            data.description = data.originalDescription.toLowerCase();
        }

        if (this.options.financeCompanyIndex) {
            const financeCompany = $(
                'td:nth-child(' + this.options.financeCompanyIndex + ')',
                item,
            );

            data.originalFinanceCompany = financeCompany.text();
            data.financeCompany = data.originalFinanceCompany.toLowerCase();
            data.financed = new Boolean(
                financeCompany.data('financed'),
            ).valueOf();
        }

        this.items[id] = data;

        return data;
    };

    /**
     * @param {Array} items
     */
    TransactionTable.prototype.indexItems = function (items) {
        items.each(
            function (index, element) {
                const item = $(element);

                this.indexItem(item);
            }.bind(this),
        );
    };

    /**
     * @param {jQuery} item
     * @param {jQuery} tbody
     */
    TransactionTable.prototype.indexNewItem = function (item, tbody) {
        const data = this.indexItem(item, tbody);
        const filterer = this.makeFilterer();

        if (filterer(data)) {
            this.currentItems.push(data);

            this.container.trigger($.Event('transaction_table.item_added', {}));
        }
    };

    /**
     * @param {String} selector
     */
    TransactionTable.prototype.indexFromDom = function (selector) {
        $(selector, this.container).each(
            function (_, element) {
                const item = $(element);
                const tbody = item.closest('tbody');

                this.onTransactionLoaded(item, tbody);
            }.bind(this),
        );

        const tbody = this.container.find('tbody');
        const transactionSize = parseInt(tbody.data('transaction-size'), 10);
        const transactionTotal = parseInt(tbody.data('transaction-total'), 10);
        const currentPage = tbody.data('transaction-page')
            ? parseInt(tbody.data('transaction-page'), 10)
            : 2;

        if (transactionSize && transactionTotal) {
            const totalPages = bindhq.util.getTotalPages(
                transactionTotal,
                transactionSize,
            );

            if (currentPage <= totalPages) {
                this.showLoading();
                this.loadTransactions(currentPage);
            } else {
                this.onLoaded();
            }
        } else {
            this.onLoaded();
        }
    };

    bindhq.nsIn('abacus.transactionTable', {
        /**
         * @param {Integer}
         */
        NO_PAGINATION: -1,

        /**
         * @param {jQuery} container
         * @param {Object} options
         *
         * @return {Object}
         */
        create: function (container, options) {
            return new TransactionTable(container, options || {});
        },
    });
})();

const Observable = require('../Observable');
const { Address } = require('./Address');
const { GeoPoint } = require('./GeoPoint');
const { v4: uuidv4 } = require('uuid');

class Location extends Observable {
    /**
     * @param {String} uuid
     * @param {Integer} number
     * @param {Address} address
     * @param {GeoPoint} geoPoint
     */
    constructor(uuid, number, address, geoPoint) {
        super();
        this.uuid = uuid || uuidv4();
        this.number = number;
        this.address = address;
        this.geoPoint = geoPoint;
    }

    /** @return {String} */
    getUuid() {
        return this.uuid;
    }

    /** @param {Integer} number */
    setNumber(number) {
        this.number = number;
    }

    /** @return {Integer} */
    getNumber() {
        return this.number;
    }

    /** @return {Address} */
    getAddress() {
        return this.address;
    }

    /**
     *  @param {Address} address
     *  @param {GeoPoint?} geoPoint
     * */
    setAddress(address, geoPoint = null) {
        if (!(address instanceof Address)) {
            throw new Error('address should be an instance of Address');
        }
        if (geoPoint !== null && !(geoPoint instanceof GeoPoint)) {
            throw new Error('geoPoint should be an instance of GeoPoint');
        }
        this.address = address;
        this.geoPoint = geoPoint;
        super.fire('locationUpdated', this);
    }

    /** @return {GeoPoint} */
    getGeoPoint() {
        return this.geoPoint;
    }
}

module.exports = { Location };

import { Controller } from '@hotwired/stimulus';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

export default class extends Controller {
    static targets = ['uuid', 'prem', 'prdco'];

    static values = {
        premCode: String,
        prdcoCode: String,
    };

    connect() {
        if (!this.uuidTarget.value) {
            this.uuidTarget.value = uuidv4();
        }

        if (this.hasPremTarget) {
            this.#coverage(this.premTarget, this.premCodeValue);
            this.#coverage(this.prdcoTarget, this.prdcoCodeValue);
        }
    }

    /**
     * @param {HTMLElement} coverage
     * @param {String} coverageCode
     */
    #coverage(coverage, coverageCode) {
        if (coverage.value) {
            return;
        }

        coverage.value = uuidv5(coverageCode, this.uuidTarget.value);
    }
}

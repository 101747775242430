const Observable = require('../../Observable');

const { Address } = require('../../Models/Address');

const { AddressWidget } = require('../../Utils/AddressWidget');

const { CollectionEvents } = require('../../Form/Collection');

const { SubLocation, SubLocationEvents } = require('../../Models/SubLocation');

const { SubLocationBag } = require('../../Models/SubLocationBag');

const PropertyLocationEvents = {
    CHANGED:
        'bindhq.product.homeowners_dwelling_fire.property_location.changed',
};

const numeral = require('numeral');

const { v4: uuidv4 } = require('uuid');

/**
 *
 */
function cleanAddressErrors() {
    const $addressErrors = $('.address-errors');

    $addressErrors.html('');
    $addressErrors.addClass('d-none');
}

/**
 *
 */
function displayErrors(errors) {
    const $addressErrors = $('.address-errors');

    let errorsHtml = '';

    for (const errorKey of Object.keys(errors)) {
        for (const error of errors[errorKey]) {
            errorsHtml += `<li>${error}</li>`;
        }
    }

    $addressErrors.html(`<ul>${errorsHtml}</ul>`);
    $addressErrors.removeClass('d-none');
}

class PropertyLocation extends Observable {
    /**
     * @param {jQuery} container
     * @param {google.maps.Geocoder} geocoder
     * @param {LocationBag} locationBag
     */
    constructor(container, geocoder, locationBag) {
        super();

        const locationId = $('.location', container).val();
        const location = locationBag.find(locationId);

        if (!location) {
            throw new Error('Location not found: ' + locationId);
        }

        this.container = container;
        this.geocoder = geocoder;
        this.location = location;
        this.marker = null;
        this.subLocationBag = new SubLocationBag();
        this.anchor = 'property-location-' + uuidv4();
    }

    /**
     * @return {Array}
     */
    getSubLocations() {
        return this.subLocationBag.getItems();
    }

    /**
     * @param {google.maps.Map} map
     * @param {Object} latLng
     */
    #renderMarker(map, latLng) {
        if (this.marker) {
            this.marker.position = latLng;
        } else {
            bhqGoogleMaps(() => {
                this.marker = new google.maps.marker.AdvancedMarkerElement({
                    map,
                    position: latLng,
                });
            });
        }
    }

    /**
     * @param {google.maps.Map} map
     */
    #renderMap(map) {
        const geoPoint = this.location.getGeoPoint();

        if (
            geoPoint &&
            !(geoPoint.latitude === 0 && geoPoint.longitude === 0)
        ) {
            const latLng = {
                lat: geoPoint.getLatitude(),
                lng: geoPoint.getLongitude(),
            };

            map.setCenter(latLng);

            this.#renderMarker(map, latLng);
        } else {
            this.geocoder.geocode(
                { address: this.location.getAddress().getDescription() },
                (results, status) => {
                    if (status === 'OK') {
                        const latLng = results[0].geometry.location;

                        map.setCenter(latLng);

                        this.#renderMarker(map, latLng);
                    }
                },
            );
        }

        $('.address-description', this.container).html(
            this.location.getAddress().getDescription(),
        );
    }

    #initSubLocation() {
        this.container.find('.sub-location').each((i, element) => {
            const subLocation = new SubLocation(element, this.subLocationBag);
            subLocation.subscribe(SubLocationEvents.CHANGED, () =>
                this.#changed(),
            );
            subLocation.init();

            element.subLocation = subLocation;

            this.#changed();
        });
    }

    #addSubLocationListener() {
        this.container
            .get(0)
            .addEventListener(CollectionEvents.ADDED, (event) => {
                const element = event.detail.args.newElement;

                if (!element.classList.contains('sub-location')) {
                    return;
                }

                if (!this.container.get(0).contains(element)) {
                    return;
                }

                const subLocation = new SubLocation(
                    element,
                    this.subLocationBag,
                );

                subLocation.subscribe(SubLocationEvents.CHANGED, () =>
                    this.#changed(),
                );
                subLocation.init();

                this.#changed();

                element.subLocation = subLocation;
            });

        this.container
            .get(0)
            .addEventListener(CollectionEvents.REMOVED, (event) => {
                const element = event.detail.args.removedElement;

                if (!element.classList.contains('sub-location')) {
                    return;
                }

                const subLocation = element.subLocation;

                if (this.subLocationBag.contains(subLocation)) {
                    this.subLocationBag.remove(subLocation);
                    this.#changed();
                }
            });
    }

    #initSubLocations() {
        this.#initSubLocation();
        this.#addSubLocationListener();
    }

    #changed() {
        super.fire(PropertyLocationEvents.CHANGED);
    }

    /**
     *
     */
    #initAddressEdit() {
        const addressLookup = new AddressWidget(this.container, this.location);

        addressLookup
            .init({
                addressLookupContainerSelector: '.address-lookup-container',
            })
            .subscribe('addressDetailsFound', ({ address, geoPoint }) => {
                this.location.setAddress(address, geoPoint);
                cleanAddressErrors();
            })
            .subscribe('addressSaved', (address) => {
                this.location.setAddress(
                    new Address(
                        address.address_line1,
                        address.address_line2,
                        address.city,
                        address.state,
                        this.location.address.internationalState,
                        address.zip,
                        address.county,
                        null,
                    ),
                );

                cleanAddressErrors();
            })
            .subscribe('addressErrors', (errors) => {
                displayErrors(errors);
            });
    }

    /** @return {Location} */
    getLocation() {
        return this.location;
    }

    init() {
        const mapContainer = this.container.findOne('.map-container');
        const map = new google.maps.Map(mapContainer.get(0), {
            mapId: 'BINDHQ_MAP',
            zoom: 14,
        });

        this.#renderMap(map);

        this.location.subscribe('locationUpdated', () => {
            this.#renderMap(map);
        });

        this.container.findOne('.location-number').on('change', (event) => {
            const locationNumber = parseInt(event.currentTarget.value, 10);

            this.location.setNumber(locationNumber);
            this.#changed();
        });

        this.container
            .attr('id', this.anchor)
            .findOne('.location-number')
            .html(this.location.getNumber());

        this.#initSubLocations();
        this.#initAddressEdit();
    }

    /**
     * @return {String}
     */
    getAnchor() {
        return this.anchor;
    }
}

module.exports = { PropertyLocation, PropertyLocationEvents };
